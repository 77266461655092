<template>
  <div>
    <h4>
      Speaking:
      <span>
        <b style="color: #ff8920;">
          <span class="mr-2" v-if="score > 0">{{ score }}</span>
          <span v-if="!examInfo.can_suggest">
            <el-button type="warning" @click="$emit('openWarningSpeaking')">
              <i class="fas fa-lock"></i>
            </el-button>
          </span>
        </b>
      </span>
    </h4>
    <div class="answer-list">
      <table
        :class="hideTitle ? 'table' : 'table table-bordered'"
        v-for="(item, index) in list"
        :key="item.exam_question_id"
      >
        <thead :style="hideTitle ? 'display:none' : ''">
          <tr>
            <th class="green-td view-question-tr">
              <el-tooltip
                v-if="item.user_answer && item.user_answer[0]"
                class="item"
                effect="dark"
                :content="showTime(item.toefl_question_type)"
                placement="top"
              >
                <a
                  class="view_question"
                  :href="getResolve(item.toefl_question_id)"
                >
                  <b>Question {{ index + 1 }}</b> <i class="far fa-eye"></i>
                </a>
              </el-tooltip>
              <a
                v-else
                class="view_question"
                :href="getResolve(item.toefl_question_id)"
              >
                <b>Question {{ index + 1 }}</b> <i class="far fa-eye"></i>
              </a>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="row audio-row">
              <div class="col-md-12">
                <div
                  v-if="item.user_answer && item.time > 0"
                  style="display: flex"
                >
                  <audio
                    style="height:40px;margin-right:20px;flex:1"
                    :id="item.exam_question_id"
                    :src="item.user_answer[0]"
                    controls
                    controlsList="nodownload"
                  ></audio>
                  <el-dropdown
                    style="width:120px;line-height:40px"
                    size="small"
                    @command="changeSpeed"
                    :key="index"
                  >
                    <span class="audio-speed">
                      <b class="text-success">Speed: {{ item.speed }} X</b>
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-for="(it, index) in multipleArray"
                        :key="index"
                        :command="composeValue(it, item)"
                      >
                        {{ it }} X
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div v-else class="text-danger">
                  N/A
                </div>
              </div>
              <div class="col-sm-12">
                <el-divider content-position="left"
                  >Grades and Comments</el-divider
                >
              </div>
              <div class="col-sm-12 text-left d-flex align-items-center">
                <b class="score-title">Response Score:</b>
                <div v-if="examInfo.can_score || teacherScored">
                  <b v-if="item.score > 0">
                    <span class="score">
                      {{ `${item.score}/4` }}
                    </span>
                  </b>
                  <div v-else class="unlockTag">
                    {{ $t("message.unlockScored") }}
                  </div>
                </div>
                <el-tooltip
                  v-else
                  class="item"
                  effect="dark"
                  :content="$t('toefl.balance.Show Score')"
                  placement="top"
                >
                  <el-button
                    type="warning"
                    @click="$emit('openWarningSpeaking')"
                  >
                    <i class="fas fa-lock"></i>
                  </el-button>
                </el-tooltip>
              </div>
              <SubScoreTable
                v-if="commentCategories[TEST_CATEGORY]"
                :value="(item.answer_score_detail || {}).detail_score_comments"
                :testCategory="TEST_CATEGORY"
                :testCategories="commentCategories[TEST_CATEGORY]"
                :canScore="examInfo.can_score || teacherScored"
                :scored="examInfo.is_scored"
                @unlock="$emit('openWarningSpeaking')"
              />
              <div class="col-sm-12 text-left">
                <div class="overall-comments score-title">
                  <b>Overall Comments:</b>
                </div>
                <div v-if="examInfo.can_suggest || teacherScored">
                  <div v-if="examInfo.is_scored" v-html="item.suggest" />
                  <div v-else class="unlockTag">
                    {{ $t("message.unlockScored") }}
                  </div>
                </div>
                <el-tooltip
                  v-else
                  class="item"
                  effect="dark"
                  :content="$t('toefl.balance.Get Comments for This Prompt')"
                  placement="top"
                >
                  <el-button
                    type="warning"
                    @click="$emit('openWarningSpeaking')"
                  >
                    <i class="fas fa-lock"></i>
                  </el-button>
                </el-tooltip>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import SubScoreTable from "@/views/toefl/transcript/components/SubScoreTable";

export default {
  components: {
    SubScoreTable
  },

  mixins: [],

  props: ["list", "score", "examInfo", "isPDF", "commentCategories", "hideTitle"],
  data() {
    return {
      multipleArray: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 2]
    };
  },
  computed: {
    TEST_CATEGORY() {
      return "speaking_all";
    },
    ...mapState("user", ["profile"]),
    teacherScored() {
      return this.examInfo.can_score === 0 && this.examInfo.can_suggest === 1 &&  this.examInfo.is_scored===1;
    }
  },

  watch: {
    list() {
      this.getAudio();
    }
  },

  async mounted() {
    await this.getProfile();
    this.getAudio();
  },
  methods: {
    getAudio() {
      let speaking_list = this.list;
      speaking_list.forEach(element => {
        let audio = document.getElementById(element.exam_question_id);
        audio.load();
        audio.oncanplay = function() {
          element.progress = audio.currentTime;
          element.time = audio.duration;
          element.speed = 1;
        };
        this.$set(element, "progress", 0);
      });
    },
    composeValue(it, item) {
      return {
        it: it,
        item: item
      };
    },
    changeSpeed(command) {
      let audio = document.getElementById(command.item.exam_question_id);
      audio.playbackRate = command.it;
      command.item.speed = command.it;
    },
    controlAudio(item) {
      let audio = document.getElementById(item.exam_question_id);
      audio.addEventListener(
        "timeupdate",
        function() {
          //更新进度条
          item.progress = audio.currentTime;
        },
        false
      );
      audio.addEventListener(
        "ended",
        function() {
          //播放完成
          item.progress = 0;
          item.canPlay = true;
        },
        false
      );
      if (item.canPlay) {
        audio.play();
        item.canPlay = false;
      } else {
        audio.pause();
        item.canPlay = true;
      }
    },
    getTime(seconds_remaining) {
      var minute;
      var second;
      minute = Math.floor(seconds_remaining / 60);
      second = Math.floor(seconds_remaining) - minute * 60;
      // 让个位数的时间正常显示
      if (minute <= 9) minute = "0" + minute;
      if (second <= 9) second = "0" + second;
      return minute + ":" + second;
    },
    getResolve(question_id) {
      return this.$parent.getResolve(question_id);
    },
    saveScore(user_exam_answer_id, score) {
      this.$parent.saveScore(user_exam_answer_id, score);
    },
    showTime(type) {
      let seconds = 0;
      if (type === "default") {
        seconds = 45;
      } else {
        seconds = 60;
      }
      let minute;
      let second;
      if (seconds && seconds > 0) {
        minute = Math.floor(seconds / 60);
        second = Math.floor(seconds) - minute * 60;
      } else {
        minute = 0;
        second = 0;
      }
      // 让个位数的时间正常显示
      if (minute <= 9) minute = "0" + minute;
      if (second <= 9) second = "0" + second;
      return `花費時間： ${minute}:${second}`;
    },
    ...mapActions("user", ["getProfile"])
  }
};
</script>

<style scoped>
.speaking-answer {
  line-height: 40px;
}
.answer-list {
  margin-top: 20px;
}

.view_question {
  color: #fff !important;
  text-decoration: none;
}

.view-question-tr {
  cursor: pointer;
  position: relative;
}

.view-question-tr:hover {
  opacity: 0.7;
}

.view_question:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.audio-row {
  margin: 0;
  padding: 32px;
}

.score-title {
  margin-right: 4px;
  font-size: 20px;
}

.score {
  color: #ff8920;
  font-size: 24px;
}

.overall-comments {
  margin-top: 4px;
  margin-bottom: 20px;
}

div a {
  cursor: pointer;
}
.unlockTag {
  color: rgb(142, 199, 166);
  background-color: rgb(236, 246, 240);
  border: 1px solid rgb(217, 236, 225);
  border-radius: 20px;
  padding: 12px;
  line-height: 1.3;
  font-size: 14px;
  text-align: left;
  width: max-content;
  margin: 0px auto;
}
@media screen and (max-width: 768px) {
  .text-left a {
    float: left !important;
  }
  .grader-comments {
    text-align: center;
  }
  .answer-list {
    overflow: auto;
  }
  .answer-list .table {
    width: 100%;
  }
  .audio-row {
    padding: 15px 0;
  }
  .score-title {
    margin-right: 4px;
    font-size: 16px;
  }
}
</style>
